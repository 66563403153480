import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/Seo';

export default function AdmissionsPage() {
  return (
    <>
      <SEO
        title="Admissions"
        description="We seek graduate students who desire the wisdom of Christ and wish to make their personal witness and service to the Church’s apostolic mission more effective"
      />
      <main className="page-admissions">
        <section className="container section-m-t page-m-b">
          <div className="row reverse-sm">
            <div className="col-xs-12 col-sm-6 col-md-offset-1 col-lg-5 col-lg-offset-1">
              <div className="hide-sm">
                <h2>Admissions</h2>
                <p>
                  We seek graduate students who desire the wisdom of Christ and
                  wish to make their personal witness and service to the
                  Church’s apostolic mission more effective.
                </p>
                <p className="m-b">
                  The Admissions Office accompanies students through the process
                  of discerning whether the graduate programs of the Augustine
                  Institute will best fit their educational goals.
                </p>
              </div>
              <h3>Admission Requirements</h3>
              <p>
                The following items are required to be considered for admission:
              </p>
              <ol>
                <li>Online application</li>
                <li>
                  Official transcripts of all completed undergraduate and
                  graduate work, submitted electronically or via mail
                </li>
                <li>Personal statement</li>
                <li>
                  Academic essay (the online application will provide you with
                  the essay prompts)
                </li>
                <li>
                  Three confidential letters of recommendation (if you are a
                  recent college graduate, please submit at least one letter
                  from a college professor)
                </li>
                <li>Optional writing sample</li>
                <li>
                  Phone interview with a member of the Admissions Committee
                </li>
                <li>$25 application fee</li>
              </ol>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-lg-offset-1">
              <div className="hide-xs show-sm">
                <h2>Admissions</h2>
                <p>
                  We seek graduate students who desire the wisdom of Christ and
                  wish to make their personal witness and service to the
                  Church’s apostolic mission more effective.
                </p>
                <p>
                  The Admissions Office accompanies students through the process
                  of discerning whether the graduate programs of the Augustine
                  Institute will best fit their educational goals.
                </p>
              </div>
              <div className="admissions__img">
                <StaticImage
                  src="../images/admissions.jpg"
                  alt="Admissions"
                  // formats={['auto', 'webp', 'avif']}
                  placeholder="blurred"
                />
                <div className="admissions__img__shape admissions__img__shape--1">
                  <div />
                </div>
                <div className="admissions__img__shape admissions__img__shape--2">
                  <div />
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
